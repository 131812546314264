import dayjs from "dayjs";

/**
 * @description Capitalize text
 * @param {any} value
 * @returns {any}
 * @author Cristian Molina
 */
export const capitalize = function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

/**
 * @description Format date to DD/MM/YYYY
 * @param {any} value
 * @returns {any}
 * @author Cristian Molina
 */
export const date = function(value) {
  if (!value) return "";
  return dayjs(value).format("DD/MM/YYYY");
};
