export const api = {
  scheme: process.env.VUE_APP_API_SCHEME,
  host: process.env.VUE_APP_API_IP,
};

export const endpoints = {
  admin: {
    path: "dl-admin",
    controllers: {
      rules: {
        path: "mant/rules",
      },
      groups: {
        path: "mant/groups",
      },
    },
  },
};
