<template>
  <v-dialog v-model="show" persistent hide-overlay max-width="800" width="500">
    <v-card>
      <v-card :color="options.color" min-height="10" flat tile class="pa-1">
      </v-card>
      <div class="pa-6">
        <v-card-title class="justify-center pb-0 ">
          <v-icon :color="options.color" x-large>
            {{ options.icon }}
          </v-icon>
        </v-card-title>
        <v-card-title class="justify-center">{{ options.title }} </v-card-title>
        <v-card-text class="justify-center text-center">
          {{ options.text }}
        </v-card-text>
        <v-card-actions class="pt-6">
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" small text>
            {{ options.cancelText }}
          </v-btn>
          <v-btn :color="options.color" @click="confirm()" small>
            {{ options.confirmText }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Dialog component for alert purpose, can catch a callback in onConfirm and onCancel.
 * @author Cristian Molina
 */
export default {
  name: "alert-dialog",
  data: () => ({
    show: false,
    options: {
      icon: "mdi-information",
      color: "info",
      title: "Información",
      text: "",
      detailText: "",
      html: "",
      confirmText: "Aceptar",
      cancelText: "Cancelar",
      onConfirm: null,
      onCancel: null,
    },
  }),
  methods: {
    /**
     * @description default open dialog, can get custom colors and icons in options object.
     * @param {any} opts = {icon,color,title,text,detailText,html,confirmText,cancelText,onConfirm,onCancel}
     * @author Cristian Molina
     */
    default(opts = {}) {
      this.options = { ...this.options, ...opts };
      this.show = true;
    },
    /**
     * @description information open dialog
     * @param {any} opts = {icon,color,title,text,detailText,html,confirmText,cancelText,onConfirm,onCancel}
     * @author Cristian Molina
     */
    info(opts = {}) {
      opts.color = "info";
      opts.icon = "mdi-information";
      this.options = { ...this.options, ...opts };
      this.show = true;
    },
    /**
     * @description error open dialog
     * @param {any} opts = {icon,color,title,text,detailText,html,confirmText,cancelText,onConfirm,onCancel}
     * @author Cristian Molina
     */
    error(opts = {}) {
      opts.color = "error";
      opts.icon = "mdi-close-circle";
      this.options = { ...this.options, ...opts };
      this.show = true;
    },
    /**
     * @description warning open dialog
     * @param {any} opts = {icon,color,title,text,detailText,html,confirmText,cancelText,onConfirm,onCancel}
     * @author Cristian Molina
     */
    warning(opts = {}) {
      opts.color = "warning";
      opts.icon = "mdi-alert-circle";
      this.options = { ...this.options, ...opts };
      this.show = true;
    },
    /**
     * @description success open dialog
     * @param {any} opts = {icon,color,title,text,detailText,html,confirmText,cancelText,onConfirm,onCancel}
     * @author Cristian Molina
     */
    success(opts = {}) {
      opts.color = "success";
      opts.icon = "mdi-check-circle";
      this.options = { ...this.options, ...opts };
      this.show = true;
    },
    /**
     * @description Cancel dialog
     * @author Cristian Molina
     */
    cancel() {
      this.close();
      this.options.onCancel(null);
      this.clearOptions();
    },
    /**
     * @description Confirm dialog
     * @author Cristian Molina
     */
    confirm() {
      this.close();
      this.options.onConfirm(null);
      this.clearOptions();
    },
    /**
     * @description Clear options
     * @author Cristian Molina
     */
    clearOptions() {
      this.options = {
        icon: "mdi-information",
        color: "info",
        title: "Información",
        text: "",
        detailText: "",
        html: "",
        confirmText: "Aceptar",
        cancelText: "Cancelar",
        onConfirm: null,
        onCancel: null,
      };
    },
    /**
     * @description Close Dialog
     * @author Cristian Molina
     */
    close() {
      this.show = false;
    },
  },
};
</script>
