<template>
  <div>
    <v-app id="inspire">
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
@import url("../node_modules/animate.css/animate.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.animate_animated {
  transition: height 0.6s cubic-bezier(1, 0.01, 1, 0.01);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* for vertical scrollbars */
  height: 10px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track:hover {
  background: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--v-primary-lighten1);
}
</style>
