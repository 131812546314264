import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Auth.vue"),
  },
  {
    path: "/dash",
    redirect: "/dash/maintainers/groups",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/Dashboard.vue"
      ),
    children: [
      {
        path: "maintainers/groups",
        name: "MaintainerGroups",
        component: () =>
          import(
            /* webpackChunkName: "maintainergroups" */ "../views/dashboard/maintainers/Groups/Groups.vue"
          ),
      },
      {
        path: "maintainers/rules",
        name: "MaintainerRules",
        component: () =>
          import(
            /* webpackChunkName: "maintainerrules" */ "../views/dashboard/maintainers/Rules/Rules.vue"
          ),
      },
    ],
  },
  {
    path: "/**",
    redirect: "/auth",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
