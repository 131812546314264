/**
 * Install plugin
 * @param Vue
 * @param superagent
 * @param superagent-use
 * @param superagent-prefix
 */

import superagent from "superagent";
import use from "superagent-use";
import prefix from "superagent-prefix";

function plugin(Vue, options) {
  const baseUrl = options && options.baseUrl;
  const headers = options && options.headers;

  if (plugin.installed) {
    return;
  }

  if (options) {
    const agent = use(superagent);
    if (baseUrl) {
      agent.use(prefix(baseUrl));
    }
    if (headers) {
      agent.use((req) => {
        headers.forEach((header) => {
          let key = Object.keys(header)[0];
          req.header[key] = header[key];
        });
        return req;
      });
    }
    Vue.superagent = agent;
  } else {
    Vue.superagent = superagent;
  }

  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return Vue.superagent;
      },
    },

    $superagent: {
      get() {
        return Vue.superagent;
      },
    },
  });
}

export default plugin;
