import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#74BB20",
        secondary: "#0047BA",
        tertiary: "#E7054C",
        accent: "#969798",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#F0F2F5",
      },
      dark: {
        primary: "#74BB20",
        secondary: "#0047BA",
        tertiary: "#E7054C",
        accent: "#969798",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#18191A",
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
