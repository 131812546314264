"use strict";

import Index from "../components/AlertDialog.vue";

export default {
  install(Vue, options = {}) {
    var extendComponent = Vue.extend(Index);

    var instance = new extendComponent({
      vuetify: options.vuetify,
    });

    Vue.prototype.$nextTick(() => {
      Vue.prototype.$alert = instance.$mount();
    });
  },
};
