import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./shared/plugins/vuetify";
import "@babel/polyfill";
import VueSuperagent from "./shared/plugins/superagent";
import { api } from "./environment/environment";
import alert from "./shared/plugins/alertdialog";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import { date, capitalize } from "@/shared/libs/filters";

Vue.use(VuetifyToast, {
  x: "right",
  y: "bottom",
  color: "info",
  icon: "mdi-information",
  iconColor: "",
  classes: ["body-1"],
  timeout: 3000,
  dismissable: true,
  multiLine: false,
  vertical: false,
  queueable: false,
  showClose: true,
  closeText: "",
  closeIcon: "mdi-close",
  closeColor: "",
  slot: [],
  shorts: {
    success: {
      color: "success",
      icon: "mdi-check-circle",
    },
    error: {
      color: "error",
      icon: "mdi-close-circle",
    },
    warning: {
      color: "warning",
      icon: "mdi-alert-circle",
    },
  },
  property: "$notification",
});

Vue.use(alert, {
  vuetify,
});

Vue.use(VueSuperagent, {
  baseUrl: `${api.scheme}${api.host}`,
  headers: [
    { "x-country": "CL" },
    { "x-chRef": "WEB" },
    { "x-commerce": "FALABELLA" },
    { "x-cmRef": "PORTAL" },
  ],
});

Vue.config.productionTip = false;

Vue.filter("date", date);
Vue.filter("capitalize", capitalize);

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
